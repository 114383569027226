import OffplanCard from "@components/OffplanCard/OffplanCard"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/Slider"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import useLimitOffplans from "@hooks/dataHooks/useLimitOffplans"
// import useLimitProperties from "@hooks/dataHooks/useLimitProperties"
import {
  offplanResultsSaleURL,
  propertiesRentURL,
  propertiesSaleURL,
} from "@lib/urls"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "react-bootstrap"
import "./AreaguideFeaturedPropertiesSlider.scss"
import useDeviceMedia from "@hooks/useDeviceMedia"
import {  getUserobjectData, isAuthenticated  } from "@starberry/myaccount-website-utils"
import { getOrderedImages } from "@lib/utils"
const AreaguideFeaturedPropertiesSlider = ({ module, properties, offplans, activekey, morelink }) => {
  const { isMobile } = useDeviceMedia()
  const KEYS = useMemo(() => {
    return {
      sale: "for-sale",
      rent: "for-rent",
      offplan: "off-plan",
    }
  }, [])

  const [activeKey, setActiveKey] = useState(activekey)

  // const { propertiesForSale, propertiesToLet } = useLimitProperties(properties)
  const { offplans: offplansToShow } = useLimitOffplans(offplans)

  // const [propertiesToShow, setPropertiesToShow] = useState(activekey === KEYS.sale ? propertiesForSale : propertiesToLet)
  const [userObjects, setUserObjects] = useState({});
  useEffect(() => {
    if (isAuthenticated()) {
      const getUserObjects = async () => {
          try {
              const userObjects = await getUserobjectData()
              setUserObjects(userObjects.data.data)
          } catch (e) {
              
          }
      }
      getUserObjects()
    }
  }, [])


  if (!module) return null
  const { title, heading } = module
  let moreUrl = propertiesSaleURL+`in-${morelink}`

  if (activeKey === KEYS.rent) {
    moreUrl = propertiesRentURL+`in-${morelink}`
  } else if (activeKey === KEYS.offplan) {
    moreUrl = offplanResultsSaleURL+`in-${morelink}`
  }

  const CTA = (
    <Link className="button button-orange more-btn" to={moreUrl}>
      More {activeKey === KEYS.offplan ? "Projects" : "Properties"}
    </Link>
  )
  

  return (
    <Animation
      animateInType="up"
      className="featured-slider-module-wrap section-m"
    >
      <Container className="area-guide-featured-slider-module-container">
        <div className="content-section">
          {heading && (
            <p className="heading">
              <span>{heading}</span>
            </p>
          )}
          <div className="d-flex justify-content-between">
          <h2 className="title">
            <span>{title}</span>
          </h2>
          {!isMobile && CTA && <div className="cta-section">{CTA}</div>}
          </div>
        </div>
        <div className="area-guide-featured-slider-tab-section">
          {/* <Tabs
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            className="tab-header"
            onChange={onChange}
            CTA={CTA}
          >
            {!!propertiesForSale?.length && (
              <Tab eventKey={KEYS.sale} title="For Sale">
                For Sale
              </Tab>
            )}
            {!!propertiesToLet?.length && (
              <Tab eventKey={KEYS.rent} title="For Rent">
                For rent
              </Tab>
            )}
            {!!offplansToShow.length && (
              <Tab eventKey={KEYS.offplan} title="Off Plan">
                Off Plan
              </Tab>
            )}
          </Tabs> */}

          <div className="tab-body">
            <Slider key={`tab${activeKey}`} size="sm">
              {activeKey === "off-plan"
                ? offplansToShow.length &&
                  offplansToShow
                    .slice(0, 6)
                    .map((offplan) => {
                      const imagesArray = getOrderedImages(offplan);

                      return(
                        <OffplanCard
                        minsBeds={offplan.min_bedrooms}
                        maxBeds={offplan.max_bedrooms}
                        displayBedrooms={offplan.display_bedrooms}
                        buldingType={offplan.building_type}
                        image={offplan.tile_image ? offplan.tile_image : offplan?.media_images?.length > 0 ? offplan?.media_images[0] : offplan?.images?.length > 0 ? offplan?.images[0] : ""}
                        images2={imagesArray}
                        title={offplan.title}
                        developer={offplan.developer}
                        location={offplan.display_address}
                        slug={offplan.slug}
                        videoURL={offplan?.video_module?.video_url}
                        community={offplan?.community?.title}
                        key={offplan.id}
                        completionYear={offplan.completion_year}
                        price={offplan?.price}
                        slider
                        future_launch={offplan?.future_launch}
                        ggfx_results={offplan?.ggfx_results}
                        strapi_id={offplan?.strapi_id}
                        notalgo
                        property={offplan}
                      />
                      )
                    })
                      
                    
                : null
                // propertiesToShow.length &&
                //   propertiesToShow
                //     .slice(0, 6)
                //     .map((property, index) => (
                //       <PropertyCard
                //         key={property.id}
                //         address={property.display_address}
                //         ammenities={property.accommodation_summary}
                //         description={property.description}
                //         bathrooms={property.bathroom}
                //         bedrooms={property.bedroom}
                //         building={property.building}
                //         image={property.images?.[0]}
                //         images={property?.images}
                //         price={property.price}
                //         size={property.floorarea_min}
                //         index={index}
                //         bitrix_id={property?.extra?.bitrix_id}
                //         crmID={property?.crm_id}
                //         slug={property?.slug}
                //         email={property?.crm_negotiator_id?.email}
                //         crm_negotiator_id={property?.crm_negotiator_id?.id}
                //         name={property?.crm_negotiator_id?.name}
                //         searchType={property?.search_type}
                //         imagetransforms={property?.ggfx_results}
                //         strapiID={property?.strapi_id}
                //         department={property?.department}
                //         phone={property?.crm_negotiator_id?.phone}
                //         slider
                //         singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
                //         userObjects={userObjects}
                //         link_to_employee={property?.link_to_employee}
                //         imageCount={property?.images?.length}
                //       />
                //     ))
                    }
            </Slider>
          </div>
        </div>
        {isMobile && CTA}
        {/* {cta_text && (
          <>
            <div className="divider" />
            <CTAText data={cta_text} />
          </>
        )} */}
      </Container>
    </Animation>
  )
}

export default AreaguideFeaturedPropertiesSlider
